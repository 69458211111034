import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 查看物流/api/getNavigationList

export function toBeReviewed(data){
  return request({
      url:`${baseURL.javaApi}/api/modify/toBeReviewed`,
      method: "post",
      data,

  });
}
export function toExamine(data){
  return request({
      url:`${baseURL.javaApi}/api/modify/toExamine`,
      method: "post",
      data,

  });
}
export function getModifyPrice(data){
  return request({
      url:`${baseURL.javaApi}/api/modify/getModifyPrice`,
      method: "post",
      data,

  });
}