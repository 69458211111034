<template>
	<div class="record-container">
		<div class="record-head">
			<div class="record-main">
				<div class="record-input-container">
					<input class="record-input-content" placeholder="请输入订单号" v-model="filter.orderNo" />
					<img src="../../assets/icons/icon_search.png" />
				</div>
				<button class="search-btn" @click="onSearch">查询</button>
			</div>
		</div>
		<div class="record-tab-container">
			<div class="record-tab-title">
				<div class="record-tab-list" @click="changeTab">
					<div :class="['tab-item', { active: filter.status == -1 }]" data-tab="-1">
						全部
						<span class="tab-bar"></span>
					</div>
					<div :class="['tab-item', { active: filter.status == 2 }]" data-tab="2">
						未审核
						<span class="tab-bar"></span>
					</div>
					<div :class="['tab-item', { active: filter.status == 1 }]" data-tab="1">
						已同意
						<span class="tab-bar"></span>
					</div>
					<div :class="['tab-item', { active: filter.status == 0 }]" data-tab="0">
						已拒绝
						<span class="tab-bar"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="record-list">
			<div class="infinite-list" style="overflow: auto">
				<div
					class="detail-container infinite-list-item"
					v-for="item in reviewList"
					:key="item.id"
					@click="goDetail(item)"
				>
					<div class="info">
						<span class="normal">订单号</span>
						<span>{{ item.orderNo }}</span>
					</div>
					<div class="info">
						<span class="normal">优惠金额</span>
						<span class="light-color">￥{{ item.discountPrice }}</span>
					</div>
					<div class="info">
						<span class="normal">申请人</span>
						<span>{{ item.roleName }}</span>
					</div>
					<div class="info">
						<span class="normal">申请时间</span>
						<span>{{ item.applicationTime }}</span>
					</div>
					<div class="info">
						<span class="normal">申请状态</span>
						<span
							:class="[
								'apply-state',
								{
									agree: item.status === 1,
									refuse: item.status === 0,
									waiting: item.status === 2,
								},
							]"
						>
							{{ statusMap[item.status] }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {toBeReviewed} from "@/api/priceReview.js"
// import { Toast } from 'vant';
export default {
	data() {
		return {
			filter: {
				orderNo: '',
				status: -1,
				pageNumber: 1,
				pageSize: 20,
			},
			reviewList: [],
			statusMap: {
				0: '已拒绝',
				1: '已同意',
				2: '未审核',
			},
			stock: false, // 是否到底部
		}
	},
	computed: {},
	async created() {
        this.getList()
	},
	mounted() {
		window.addEventListener('scroll', this.load, true)
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.load, true)
	},
	methods: {
		async getList() {
            const data = await toBeReviewed(this.filter)
            if (data.resultID === 1200) {
					this.reviewList = data.data
			}else{
                this.$toast(data.message)
            }
		},
		changeTab(e) {
			if (!e.target.dataset.tab) {
				return
			}
			this.filter.status = Number(e.target.dataset.tab)
			this.filter.pageNumber = 1
			this.stock = false
			this.getList()
		},
		onSearch() {
			this.filter.pageNumber = 1
			this.stock = false
			this.getList()
		},
		goDetail(item) {
			this.$router.push({
				path: `/priceReview/${item.id}`,
			})
		},
		async load() {
			let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight //document的滚动高度
			let nowScotop = document.documentElement.clientHeight || document.body.clientHeight //可视区高度
			let wheight = document.documentElement.scrollTop || document.body.scrollTop //已滚动高度
			if (scrollHeight === nowScotop) return
			if (scrollHeight - wheight - nowScotop <= 1 && !this.stock) {
				this.filter.pageNumber += 1 //每次分页+1
                 const data = await toBeReviewed(this.filter)
                if (data.resultID === 1200) {
						if (data.data.length > 0) {
							this.reviewList.push(...data.data) 
						} else {
                            this.$toast('暂无更多数据信息！')
							this.stock = true
						}
			    }else{
                    this.$toast(data.message)
                }
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.record-container {
	font-family: Source Han Sans CN, Source Han Sans CN-Regular;
	.record-head {
		background-color: #fff;
		.record-input-container {
			padding: 12px 16px;
			width: 80vw;
			position: relative;
			font-size: 14px;
			display: inline-block;
			img {
				position: absolute;
				top: 20px;
				left: 30px;
                width: 16px;
                height: 16px;
			}
		}
		.record-input-content {
			height: 30px;
			background: #f2f2f2;
			border-radius: 26px;
			border: none;
			padding-left: 40px;
			padding-right: 10px;
		}

		.search-btn {
			border: none;
			background: transparent;
			color: #333;
			font-weight: bold;
			font-size: 16px;
			line-height: 16px;
			width: 13vw;
			padding: 0;
		}
	}
			.record-tab-container {
			padding: 8px 16px 0;
			.record-tab-title {
				margin-bottom: 4px;
				white-space: nowrap;
				.record-tab-list {
					position: relative;
					padding: 0 27px;
					white-space: nowrap;
					background: #fff;
					border-radius: 10px;
					.record-active-bar {
						position: absolute;
						bottom: 0;
						left: 0;
						height: 2px;
						background-color: #8ecc33;
						z-index: 1;
						-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
						transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
						transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
						transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
							-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
						list-style: none;
					}
					.tab-item {
						display: inline-block;
						padding: 0 20px;
						height: 40px;
						-webkit-box-sizing: border-box;
						box-sizing: border-box;
						line-height: 40px;
						list-style: none;
						font-size: 14px;
						font-weight: 500;
						color: #666;
						position: relative;
						.tab-bar {
							position: absolute;
							bottom: 0px;
							height: 2px;
							left: 21px;
							width: 42px;
							background: #8ecc33;
							display: none;
						}
						&.active {
							color: #333;
							font-weight: bold;
							.tab-bar {
								display: inline-block !important;
							}
						}
						&:nth-child(1) {
							padding-left: 0;
							.tab-bar {
								width: 28px;
								left: 0px;
							}
						}
						&:last-child {
							padding-right: 0;
							.tab-bar {
								width: 42px;
								left: 21px;
							}
						}
					}
				}
			}
		}
	.record-list {
		padding: 8px 16px;
	}
	.light-color {
		color: #ff6464;
		font-size: 16px;
	}
	.waiting {
		color: #ffa450;
		&::before {
			background-color: #ffa450;
		}
	}
	.refuse {
		color: #ff6464;
		&::before {
			background-color: #ff6464;
		}
	}
	.agree {
		color: #69d881;
		&::before {
			background-color: #69d881;
		}
	}
	.detail-container {
		padding: 12px;
		margin-bottom: 16px;
		background-color: #fff;
		border-radius: 8px;
		.info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			line-height: 16px;
			padding: 8px 0;
			color: #333;
			.normal {
				color: #666;
			}
			.apply-state {
				position: relative;
				font-size: 16px;
				&::before {
					position: absolute;
					content: '';
					display: inline-block;
					width: 6px;
					height: 6px;
					left: -10px;
					border-radius: 50%;
					top: 6px;
				}
			}
		}
	}
}
</style>
